import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loading from '../Loading';
import ReturnToMerchant from '../ReturnToMerchant';
import Logo from '../Logo';

export default function StaticHeader({ isLoading, merchant, dismiss }) {
  return (
    <nav className="h-12 sm:h-16 py-4 sm:py-0 px-2 md:px-16 shadow-none flex justify-between items-center rounded-none border-solid border-b-[3px] border-border-gray">
      <div
        className={classNames(
          'w-full flex items-center',
          merchant ? 'justify-between' : 'justify-center',
        )}
      >
        <div className="flex items-center">
          {merchant &&
          merchant.integration_configuration.get('show_close_navigation') ? (
            <>
              {!isLoading && merchant && (
                <a
                  id="cancel"
                  onClick={dismiss}
                  className="text-xs md:text-base cursor-pointer text-primary-blue hover:text-primary-blue-hover mx-4 md:mx-0 md:mr-4"
                >
                  <FontAwesomeIcon icon="times" />
                </a>
              )}
              {isLoading && <Loading className="h-4 w-4" />}
            </>
          ) : (
            <div></div>
          )}

          <div className={classNames({ 'flex mx-2 sm:mx-0 sm:justify-center': isLoading })}>
            <Logo merchant={merchant} />
          </div>
        </div>
        <div>
          {merchant && (
            <div className="flex items-center mr-4 md:mx-0 gap-2 sm:gap-4 md:gap-6">
              <ReturnToMerchant merchant={merchant} />
              <a
                id="learn-more"
                href={merchant.learn_more_link}
                target="_new"
                className="flex items-center gap-2 text-sm text-primary-blue"
              >
                <FontAwesomeIcon icon={['far', 'question-circle']} />
                <p className="hidden md:inline">Learn More</p>
              </a>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}
