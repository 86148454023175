import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import AcceptBlueHostedForm from '../../third_party/accept_blue_hosted_form';
import Alert from '../../shared/Alert';
import Error from '../../shared/Error';
import Loading from '../../shared/Loading';
import PaymentMethodRecord from '../../../records/payment_method';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import ProgressBars from '../../shared/ProgressBars';
import useSessionEntity from '../../../hooks/useSessionEntity';
import { asyncActions } from '../../../actions/general';
import { createPaymentMethodCreditCard } from '../../../actions/payment_methods';
import { formStyles } from '../../../lib/constants/SiteVariables';
import { getRoute, loading } from '../../../actions/sessions';

export default function CreditCardCredentials() {
  const dispatch = useDispatch();

  const user = useSessionEntity('user');
  const isLoading = useSelector((state) => state.sessions.get('loading'));
  const [agreed, setAgreed] = useState(false);
  const [valid, setValid] = useState(true);
  const [cardForm, setCardForm] = useState(undefined);
  const [loadCardForm, setLoadCardForm] = useState(true);
  const [formInteractedWith, setFormInteractedWith] = useState(false);
  const [cardFormErrors, setCardFormErrors] = useState([]);

  useEffect(() => {
    dispatch(loading(false));
  }, []);

  useEffect(() => {
    // Toggle loading state so in modal mode the iframe resizes properly
    dispatch(loading(!cardForm));
  }, [cardForm]);

  useEffect(() => {
    if (agreed) setValid(true);
  }, [agreed]);

  const save = async () => {
    if (!agreed) {
      setValid(false);
      return;
    }

    try {
      setCardFormErrors([]);
      dispatch(loading(true));

      const processor_data = await cardForm.getNonceToken();

      return dispatch(
        asyncActions(
          createPaymentMethodCreditCard(
            new PaymentMethodRecord({
              user_id: user.id,
              processor_data,
            }),
          ),
          getRoute(),
        ),
      ).then(() => dispatch(loading(false)));
    } catch (err) {
      // Error from our API
      if (err.json) {
        err.json().then((data) => setCardFormErrors([data.error]));
      }

      // Error from AcceptBlue hosted form
      if (err.message) setCardFormErrors([err.message]);
      dispatch(loading(false));
    }
  };

  if (!user) return <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />;

  return (
    <div role="main"  className="mx-6 sm:mx-auto">
      <ProgressBars />
      <div className="mt-4 mb-1 sm:mb-4">
        <div className="text-xl sm:text-4xl font-extrabold">
          Credit Card Credentials
        </div>
      </div>
      <div className="tracking-wide font-light sm:text-lg mb-3 sm:mb-6">
        Please confirm your credit card details. This will be the card used to
        withdraw payments.
      </div>

      <Error />

      <AcceptBlueHostedForm
        setFormInteractedWith={setFormInteractedWith}
        setCardForm={setCardForm}
        cardForm={cardForm}
        loadCardForm={loadCardForm}
        setCardFormErrors={setCardFormErrors}
      />

      <div role="error" className={cardFormErrors.length === 0 ? 'hidden': 'inline'}>
        <Alert
          containerClass="bg-background-error py-2 px-4 sm:p-4 mx-auto my-3"
          icon={['far', 'triangle-exclamation']}
          alertText={cardFormErrors.join(' - ')}
        />
      </div>

      <div className="relative flex flex-col items-start my-2 sm:mt-0">
        <div className="flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="agree"
              name="agree"
              onChange={() => setAgreed(!agreed)}
              value={agreed}
              checked={agreed}
              aria-describedby="agree-checkbox"
              type="checkbox"
              className={classNames(
                'h-3 sm:h-4 w-3 sm:w-4 rounded border-tertiary-gray focus:ring-primary-blue mt-1 xs:mt-0',
                { 'border-error': !valid },
              )}
            />
          </div>
          <div className="ml-2 xs:ml-4 mt-0.5 text-xs sm:text-base leading-2 sm:leading-6">
            <label htmlFor="agree">
              I authorize Credit Key to charge scheduled charges to my credit
              card.
            </label>
          </div>
        </div>
        {!valid && <div className={formStyles.validation}>* Required</div>}
      </div>

      <PrimaryButton
        buttonText="Next"
        buttonColor="bg-primary-blue"
        hoverClass="hover:bg-primary-blue-hover"
        disabled={
          cardFormErrors.length > 0 || formInteractedWith === false || isLoading
        }
        isLoading={cardFormErrors.length > 0 || isLoading}
        onClick={save}
      />
    </div>
  );
}
