import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, Route, Switch } from 'react-router-dom';

import FullStory from '../lib/utils/full_story';
import Loading from './shared/Loading';
import StaticFooter from './shared/layout/StaticFooter';
import StaticHeader from './shared/layout/StaticHeader';
import useSessionEntity from '../hooks/useSessionEntity';
import { emitEvent, setHeight, updateParent } from '../lib/utils/modal';
import { platform, subDomains } from '../lib/constants/SiteVariables';
import { workflows, fromLearnMore } from '../App';
import { merchantIsCreditKey } from '../lib/utils/merchant_restricted_text';
import { init, loading, setPublicKey, setIsModal } from '../actions/sessions';

export default function Session(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.sessions.get('loading'));
  const session = useSelector((state) => state.sessions.get('session'));
  const cart = useSessionEntity('shopping_cart');
  const company = useSessionEntity('company');
  const user = useSessionEntity('user');
  const merchant = useSessionEntity('merchant');
  const [loaded, setLoaded] = useState(false);
  const [curPath, setCurPath] = useState();
  const { pathname } = useLocation();

  const getRedirectPath = (session) => {
    let redirect = {
      pathname: '/' + session.workflow + '/' + session.action,
      state: '',
    };

    const isNewUserApplyNow =
      session.session_type === 'apply_now' &&
      ['apply_now', 'new_user'].includes(session.action);

    const isRechargeOrInstore =
      session.custom_flags.includes('instore') ||
      session.custom_flags.includes('recharge');

    if (merchant && merchant.id && merchantIsCreditKey(merchant)) {
      return (redirect.pathname = '/invalid/merchant');
    }

    if (
      isNewUserApplyNow &&
      document.referrer.replace(/\/$/, '') !== process.env.REACT_APP_PI4 &&
      !fromLearnMore &&
      !isRechargeOrInstore
    ) {
      redirect.pathname = '/init/apply_now';
      redirect.state = { redirect_url: '/init/new_user' };
    }
    return redirect;
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const path = pathname.split('/')[2];
    setCurPath(path);

    if (
      merchant &&
      merchant.name !== '' &&
      path &&
      (path !== curPath || (path && !curPath))
    ) {
      const fs = new FullStory(path.replace(/_/g, ''), merchant);
      if (!fs.initialized()) fs.init(platform());

      if (user) {
        fs.identify(user.email, {
          displayName: user.full_name,
          merchant: merchant.name,
          company_name: user.company_name,
        });
      }

      fs.event({
        company,
        merchant,
        session,
        user,
        shopping_cart: cart,
      });

      if (session && session.mode === 'modal') {
        emitEvent(user, path, cart);
      }
    }
  }, [pathname, merchant]);

  useEffect(() => {
    const content = document.getElementById('content');

    // add height of header + footer + css spacing
    content &&
      !isLoading &&
      (!session.mode || session.mode === 'modal') &&
      setHeight(content.clientHeight + 305);
  }, [isLoading, pathname]);

  useEffect(() => {
    dispatch(setPublicKey(props.match.params));
  }, [props.match]);

  useEffect(() => {
    let redirectTo = '/init/existing_user';

    dispatch(init())
      .then(() => setLoaded(true))
      .catch((err) => {
        setLoaded(true);

        if (subDomains.authorized()) {
          const [merchant_key, prequal_id, params] = subDomains.parseParams(
            props.match.params,
          );

          if (params.get('modal')) dispatch(setIsModal(!!params.get('modal')));

          if (!merchant_key) {
            redirectTo = '/invalid';
          } else {
            // update the public_key again to make sure we pick up any change
            dispatch(setPublicKey({ public_key: merchant_key }));
            redirectTo = `/apply/${merchant_key}/${prequal_id}`;
          }
        }

        if (merchant && merchant.isOnHold) redirectTo = '/error';

        history.replace(redirectTo);
        dispatch(loading(false));
      });
  }, []);

  useEffect(() => {
    if (merchant && merchant.isOnHold) return history.push('/error');

    if (merchant && session.workflow) {
      history.push(getRedirectPath(session));
    }

    if (session.disposition === 'completed' && company) {
      history.push(`/finish/${company.underwriting_status}`);
    }
  }, [merchant, session.workflow, session.action]);

  useEffect(() => {
    if (platform() !== 'development' && session.public_key) {
      window.ckLoadZendeskWidget();
    }
  }, [session]);

  const dismiss = () => {
    if (!session.mode || session.mode === 'modal') {
      // detect if iframed
      if (window.self !== window.top) {
        return updateParent('cancel', null);
      } else {
        window.history.back();
        return true;
      }
    }

    if (session.mode !== 'modal') {
      if (cart && session.session_type === 'standalone')
        window.location = merchant.url;

      cart && cart.cancel_url
        ? (window.location = cart.cancel_url)
        : (window.location = merchant.url);
    }
  };

  return (
    <div className="flex flex-col min-h-screen xxs:h-full font-manrope">
      <StaticHeader
        isLoading={isLoading}
        merchant={merchant}
        dismiss={dismiss}
      />
      <div className="flex-grow flex items-start overflow-auto sm:mt-10">
        <div className="mx-auto max-w-xl w-full">
          {!loaded && (
            <Loading
              className="h-14 w-14 mx-auto"
              containerClass="min-h-[55vh] my-14"
            />
          )}
          {loaded && (
            <div
              id="content"
              role="session"
              className="min-h-[65vh] w-full my-6 md:my-0 overflow-y-auto px-1"
            >
              <Switch>
                {workflows.map((w) => (
                  <Route key={w.path} path={w.path} component={w.component} />
                ))}
              </Switch>
            </div>
          )}
        </div>
      </div>
      <StaticFooter />
    </div>
  );
}
