import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ApplicationRecord from '../../../records/application';
import ColumnInput from '../../shared/inputs/ColumnInput';
import Error from '../../shared/Error';
import Loading from '../../shared/Loading';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import ProgressBars from '../../shared/ProgressBars';
import StickyInput from '../../shared/inputs/StickyInput';
import StickySelect from '../../shared/inputs/StickySelect';
import Tooltip from '../../shared/Tooltip';
import useModal from '../../../hooks/useModal';
import useRouteStatus from '../../../hooks/useRouteStatus';
import useSessionEntity from '../../../hooks/useSessionEntity';
import useValidateRoute from '../../../hooks/useValidateRoute';
import { AddressMatchModal } from '../../shared/modal';
import { addressValidation } from '../../../lib/utils/validations';
import {
  asyncActions,
  clearErrors,
  handleAsyncError,
} from '../../../actions/general';
import {
  authenticate,
  getRoute,
  getSession,
  loading,
} from '../../../actions/sessions';
import {
  states,
  poBoxRegex,
  emailRegex,
  checkEmail,
} from '../../../lib/constants/SiteVariables';
import { updateApplication } from '../../../actions/applications';
import { validateBirthDate } from '../../../actions/users';

export default function Personal({ location }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.sessions.get('loading'));
  const application = useSessionEntity('application');
  const session = useSelector((state) => state.sessions.get('session'));
  const asyncErrors = useSelector((state) => state.general.get('errors'));
  const [foundUser, setFoundUser] = useState(false);
  const [forceAddress, setForceAddress] = useState(false);
  const { isModalActive, toggleModal } = useModal();

  useRouteStatus(location);

  useEffect(() => {
    if (foundUser) {
      dispatch(clearErrors());
      history.push('/init/existing_user');
    }
  }, [foundUser]);

  useEffect(() => {
    if (
      asyncErrors.count() > 0 &&
      asyncErrors.find(
        (e) =>
          e &&
          (e.includes('Phone number already in use') ||
            e.includes('Email address already in use')),
      )
    ) {
      setFoundUser(true);
      dispatch(loading(false));
    }
  }, [asyncErrors]);

  const checkBirthdate = async (val) => {
    try {
      const res = await dispatch(validateBirthDate(val));
      return res.result;
    } catch (e) {
      dispatch(handleAsyncError(e));
    }
  };

  const save = (values) => {
    dispatch(loading(true));

    return dispatch(
      asyncActions(
        updateApplication(
          new ApplicationRecord({
            ...values,
            phone: application?.get('phone')
              ? application?.get('phone')
              : values['business_phone'],
          }),
        ),
        authenticate(),
        getSession({ cart: false, prequal: false, merchant: false }),
        getRoute(),
      ),
    ).then((res) => dispatch(loading(false)));
  };

  if (!useValidateRoute({ apply: true, company: true })) return <></>;

  if (!application)
    return <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />;

  return (
    <div role="main" className="mx-6 sm:mx-auto">
      <ProgressBars />
      <div className="mt-4 mb-1 sm:mb-4">
        <div className="text-base sm:text-xl sm:text-4xl font-extrabold sm:mb-2">
          Personal Address & Details
        </div>
      </div>
      <div className="tracking-wide mb-4 sm:mb-6 font-light text-sm sm:text-lg">
        Provide your personal information so we can set up your account.
        Remember, applying will not affect your personal credit.
      </div>

      <Error />

      <Formik
        initialValues={application.toJS()}
        validate={async (values) => {
          const errors = {};
          const addNested = (field, msg) =>
            (errors['personal_address'] = {
              ...errors['personal_address'],
              [field]: msg,
            });

          if (!values.first_name || values.first_name.trim().length < 2) {
            errors.first_name = '* Required';
          }
          if (!values.last_name || values.last_name.trim().length < 2) {
            errors.last_name = '* Required';
          }
          if (!values.email) {
            errors.email = '* Required';
          } else if (
            !emailRegex.test(values.email.toLowerCase()) ||
            !checkEmail(values.email.toLowerCase())
          ) {
            errors.email = '* Invalid email address';
          }

          if (
            !values.personal_address.address1 ||
            values.personal_address.address1.trim().length < 2
          ) {
            addNested('address1', '* Required');
          }

          if (poBoxRegex.test(values.personal_address.address1.trim())) {
            addNested('address1', '* P.O. Boxes are not accepted.');
          } else if (poBoxRegex.test(values.personal_address.address2.trim())) {
            addNested('address2', '* P.O. Boxes are not accepted.');
          }

          if (
            !values.personal_address.city ||
            values.personal_address.city.trim().length < 2
          ) {
            addNested('city', '* Required');
          }

          if (!values.personal_address.state) addNested('state', '* Required');

          if (!values.personal_address.zip) {
            addNested('zip', '* Required');
          } else if (values.personal_address.zip.trim().length < 5) {
            addNested('zip', '* Must be 5 characters');
          }

          if (!values.ssn) {
            errors.ssn = '* Required';
          } else if (values.ssn.replace(/[^\d]/g, '').length < 9) {
            errors.ssn = '* Must be 9 characters in length';
          }

          if (!values.birthdate) {
            errors.birthdate = '* Required';
          } else if (values.birthdate.replace(/[^\d]/g, '').length < 8) {
            errors.birthdate = '* Please enter a valid date';
          }

          return errors;
        }}
        onSubmit={async (values, { setFieldError }) => {
          dispatch(loading(true));

          const addressesMatch = addressValidation(
            application?.get('business_address').toJS(),
            values?.personal_address,
          );

          if (addressesMatch && !forceAddress) toggleModal();

          const bdayCheck = await checkBirthdate(values.birthdate);

          if (!bdayCheck.valid) {
            const msg_lookup = {
              owner_under_18: 'You must be 18 or over to apply',
              invalid_format: 'Please enter a valid date',
            };
            const message = bdayCheck.error
              ? msg_lookup[bdayCheck.error]
              : msg_lookup['invalid_format'];

            setFieldError('birthdate', `* ${message}`);
          }

          if (bdayCheck.valid && (!addressesMatch || forceAddress)) {
            save(values);
          } else {
            dispatch(loading(false));
          }
        }}
      >
        {({ errors, touched, values, submitForm }) => {
          return (
            <Form role="form" className="mx-auto">
              <ColumnInput
                error={{
                  first_name: errors?.first_name,
                  last_name: errors?.last_name,
                }}
                touched={{
                  first_name: touched?.first_name,
                  last_name: touched?.last_name,
                }}
                className="flex flex-col mobile:flex-row justify-between mobile:gap-x-1"
              >
                <StickyInput
                  id="first_name"
                  className="mobile:w-1/2 w-full mb-2 mobile:my-0"
                  placeholder="First Name"
                  values={values}
                />

                <StickyInput
                  id="last_name"
                  className="mobile:w-1/2 w-full mb-1 mobile:my-0"
                  placeholder="Last Name"
                  values={values}
                />
              </ColumnInput>

              <ColumnInput error={errors.email} touched={touched.email}>
                <StickyInput
                  id="email"
                  type="email"
                  placeholder="Email"
                  values={values}
                />
              </ColumnInput>
              <ColumnInput
                error={
                  errors.personal_address && errors.personal_address.address1
                }
                touched={
                  touched.personal_address && touched.personal_address.address1
                }
              >
                <StickyInput
                  id="personal_address.address1"
                  placeholder="Personal Street Address"
                  values={values}
                />
              </ColumnInput>

              <ColumnInput
                error={
                  errors.personal_address && errors.personal_address.address2
                }
                touched={
                  touched.personal_address && touched.personal_address.address2
                }
              >
                <StickyInput
                  id="personal_address.address2"
                  placeholder="Suite or Floor"
                  values={values}
                />
              </ColumnInput>

              <ColumnInput
                error={{
                  city: errors?.personal_address?.city,
                  state: errors?.personal_address?.state,
                  zip: errors?.personal_address?.zip,
                }}
                touched={{
                  city: touched?.personal_address?.city,
                  state: touched?.personal_address?.state,
                  zip: touched?.personal_address?.zip,
                }}
                className="flex flex-col mobile:flex-row justify-between mobile:gap-x-1"
              >
                <StickyInput
                  id="personal_address.city"
                  className="mobile:w-3/5 w-full mb-2 mobile:my-0"
                  placeholder="City"
                  values={values}
                />
                <StickySelect
                  id="personal_address.state"
                  className="mobile:w-1/5 w-full mb-2 mobile:my-0"
                  placeholder="State"
                  options={states}
                  shortOptionsDisplay={true}
                  values={values}
                />
                <StickyInput
                  id="personal_address.zip"
                  className="mobile:my-0"
                  mask="99999"
                  type="tel"
                  placeholder="Zip"
                  values={values}
                />
              </ColumnInput>

              <ColumnInput
                error={{
                  birthdate: errors?.birthdate,
                  ssn: errors?.ssn,
                }}
                touched={{
                  birthdate: touched?.birthdate,
                  ssn: touched?.ssn,
                }}
                className="flex flex-col mobile:flex-row justify-between mobile:gap-x-1"
              >
                <StickyInput
                  id="birthdate"
                  type="tel"
                  placeholder="Date of Birth"
                  inputMode="numeric"
                  mask="99/99/9999"
                  className="mobile:w-1/2 w-full my-1 mobile:my-0"
                  values={values}
                />

                <StickyInput
                  id="ssn"
                  type="tel"
                  placeholder="SSN"
                  inputMode="numeric"
                  mask="999-99-9999"
                  className="mobile:w-1/2 w-full my-1 mobile:my-0"
                  values={values}
                  disclaimer={
                    <Tooltip
                      tooltipText="Your SSN is required to verify your identity & process your application. There is no impact on your personal credit to apply with Credit Key."
                      triggerClass="sm:px-1 sm:mt-1 text-xs sm:text-sm hover:cursor-pointer hover:text-primary-blue text-left"
                      containerClass="rounded-lg w-72 p-3 bg-border-gray text-xs"
                    >
                      <FontAwesomeIcon
                        icon={['fal', 'circle-question']}
                        className="mr-1"
                      />
                    </Tooltip>
                  }
                />
              </ColumnInput>
              <p className="text-xs mt-4 mb-2 text-secondary-gray">
                By clicking continue, I agree to the
                <a
                  href="https://www.creditkey.com/legal/terms-of-service-and-electronic-disclosures"
                  target="_new"
                  className="mx-1 text-primary-blue hover:text-primary-blue-hover underline"
                >
                  Credit Key Terms of Service & Electronic Disclosures
                </a>
                and
                <a
                  href="https://www.creditkey.com/legal/privacy-policy"
                  target="_new"
                  className="mx-1 text-primary-blue hover:text-primary-blue-hover underline"
                >
                  Privacy Policy
                </a>
                .
              </p>
              <PrimaryButton
                buttonText={
                  session.hasCustomFlag('vip')
                    ? 'Submit Application'
                    : 'Continue'
                }
                buttonColor="bg-primary-blue"
                hoverClass="hover:bg-primary-blue-hover"
                disabled={isLoading}
                isLoading={isLoading}
              />
              <AddressMatchModal
                businessAddress={application?.get('business_address').toJS()}
                isModalActive={isModalActive}
                personalAddress={values?.personal_address}
                setForceAddress={setForceAddress}
                toggleModal={toggleModal}
                handleSubmit={submitForm}
                isLoading={isLoading}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
