import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  existingUserEvent,
  existingUserEventGA,
  afterAuthRoute,
  reestablishSession,
  loading,
} from '../../../actions/sessions';

import Alert from '../../shared/Alert';
import GA from '../../../lib/utils/ga';
import Heap from '../../../lib/utils/heap';
import Loading from '../../shared/Loading';
import UserTokenVerification from '../../shared/UserTokenVerification';
import { asyncActions } from '../../../actions/general';
import { setToken, tokenIsExpired } from '../../../actions/authentication';
import { emitEvent } from '../../../lib/utils/modal';
import { useAuth } from '../../../hooks/useAuth';

const heap = new Heap('ExistingUser');
const ga = new GA();

export default function ExistingUserToken({ match }) {
  const { verify } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.sessions.get('loading'));
  const token = useSelector((state) => state.authentication.get('token'));

  const pathParts = match?.path.split('/');

  const resendCode = () => {
    dispatch(loading(true));
    return dispatch(asyncActions(verify('phone', match.params.contact, true)))
      .then(() => dispatch(loading(false)))
      .catch((err) => dispatch(loading(false)));
  };

  if (!match || (!match.params.type && !match.params.contact)) {
    return (
      <div role="error">
        <Alert
          containerClass="bg-red-50 p-4 mx-auto my-3"
          icon={['far', 'circle-xmark']}
          alertText={
            <p>
              Something went wrong, please
              <Link
                to="/init/existing_user"
                className="mx-1 underline cursor-pointer"
              >
                go back
              </Link>
              and try again.
            </p>
          }
        />
      </div>
    );
  }

  const afterAuthCallback = (twofactor, verifiedUser, verifiedToken) => {
    return dispatch(
      asyncActions(
        !twofactor && reestablishSession(verifiedUser, verifiedToken),
        pathParts[1] === 'init' && emitEvent(verifiedUser),
        pathParts[1] === 'init' && existingUserEvent(heap, verifiedUser),
        pathParts[1] === 'init' && existingUserEventGA(ga, verifiedUser),
      ),
    )
      .then(() => dispatch(afterAuthRoute(history, twofactor)))
      .finally(() => dispatch(loading(false)));
  };

  const onLockout = () => {
    history.push('/init/user_lockout');
  };

  const onNotBorrower = () => {
    history.push('/init/user_is_not_borrower');
  };

  return (
    <div role="main"  className="mx-6 sm:mx-auto">
      <div className="mt-4 mb-1 sm:mb-4">
        <div className="text-xl sm:text-4xl font-extrabold">
          We just {match.params.type === 'phone' ? 'texted' : 'emailed'} you
        </div>
      </div>
      <div className="tracking-wide w-4/5 sm:w-full mb-2 sm:mb-6 font-light text-sm sm:text-lg break-words">
        Please enter the verification code we sent to
        <span className="text-primary-blue-hover block sm:inline sm:mx-1">{match.params.contact}.</span>
      </div>

      <div className="flex justify-start">
        <UserTokenVerification
          expired={tokenIsExpired(token)}
          isLoading={isLoading}
          match={match}
          onAfterAuth={afterAuthCallback}
          onLoading={loading}
          onLockout={onLockout}
          onNotBorrower={onNotBorrower}
          onSaveToken={setToken}
        >
          <Loading className="w-14 h-14" />
        </UserTokenVerification>
      </div>

      <div className="flex flex-col xxs:flex-row xxs:items-center w-full my-3 sm:mt-6">
        <p className="font-light text-sm sm:text-lg">Didn't receive the code?</p>
        <Link
          to="/init/existing_user"
          className="xxs:ml-1.5 text-sm sm:text-lg text-primary-blue hover:text-primary-blue-hover"
        >
          Change Number
        </Link>
      </div>
      <p
        className="text-xs sm:text-sm text-secondary-gray underline cursor-pointer hover:text-primary-blue-hover"
        onClick={resendCode}
      >
        Send to my account email instead
      </p>
    </div>
  );
}
