import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Support from '../../shared/Support';
import { server } from '../../../lib/constants/SiteVariables';

export default function UserIsNotBorrower() {
  return (
    <div role="main"  className="mx-6 sm:mx-auto">
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-secondary-blue !mx-0"
          />
          <FontAwesomeIcon
            icon={['far', 'triangle-exclamation']}
            className="text-primary-blue text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-3xl font-extrabold ml-3">
          Your Account Is Not Authorized
        </div>
      </div>
      <div className="flex flex-col gap-3 my-6">
        <p className="font-light sm:text-lg tracking-wide">
          Your account is not authorized as a borrower with Credit Key.
        </p>
        <p className="font-light sm:text-lg tracking-wide">
          You may still login at:{' '}
          <a
            href={server()}
            className="font-semibold text-primary-blue underline"
          >
            creditkey.com
          </a>
        </p>
      </div>

      <div className="mt-6 sm:mt-12">
        <Support />
      </div>
    </div>
  );
}
