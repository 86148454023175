import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import { asyncActions, clearErrors } from '../../../actions/general';
import { formStyles } from '../../../lib/constants/SiteVariables';
import { getRoute, loading } from '../../../actions/sessions';
import { useDispatch, useSelector } from 'react-redux';

import {
  createPaymentMethodBank,
  generateBankAgreement,
} from '../../../actions/payment_methods';
import Alert from '../../shared/Alert';
import ColumnInput from '../../shared/inputs/ColumnInput';
import Error from '../../shared/Error';
import Heap from '../../../lib/utils/heap';
import Loading from '../../shared/Loading';
import PaymentMethodRecord from '../../../records/payment_method';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import ProgressBars from '../../shared/ProgressBars';
import StickyInput from '../../shared/inputs/StickyInput';
import Tooltip from '../../shared/Tooltip';
import useSessionEntity from '../../../hooks/useSessionEntity';

const heap = new Heap('BankCredentials');

export default function BankCredentials() {
  const dispatch = useDispatch();

  const merchant = useSessionEntity('merchant');
  const user = useSessionEntity('user');
  const isLoading = useSelector((state) => state.sessions.get('loading'));
  const session = useSelector((state) => state.sessions.get('session'));
  const asyncErrors = useSelector((state) => state.general.get('errors'));
  const [bankAgreement, setBankAgreement] = useState(null);
  const [bankProblem, setBankProblem] = useState(false);
  const form = useRef(null);

  useEffect(() => {
    const invalid = asyncErrors.find((e) => e && e.includes('invalid_bank'));
    const excessive = asyncErrors.find(
      (e) => e && e.includes('excessive_attempts'),
    );

    if (excessive) {
      heap.track({
        merchant: merchant,
        session,
        user: user,
        payload: { invalid: true },
      });

      dispatch(getRoute()).then(() => dispatch(loading(false)));
    } else if (invalid) {
      heap.track({
        merchant: merchant,
        session,
        user: user,
        payload: { failed: true },
      });

      window.scrollTo(0, 0);

      setBankProblem(true);
      form.current.setErrors({
        bank_account: 'Double check the account number and try again.',
        bank_routing: 'Double check the routing number and try again.',
      });

      dispatch(loading(false));
    }

    dispatch(clearErrors());
  }, [asyncErrors]);

  useEffect(() => {
    dispatch(loading(true));
    dispatch(asyncActions(generateBankAgreement(user)))
      .then((res) => {
        if (res) setBankAgreement({ __html: res.attributes.data });
      })
      .then(() => dispatch(loading(false)));
  }, []);

  const save = (values) => {
    dispatch(loading(true));
    return dispatch(
      asyncActions(
        createPaymentMethodBank(new PaymentMethodRecord(values), session.id),
        getRoute(),
      ),
    ).then(() => dispatch(loading(false)));
  };

  if (!user)
    return <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />;

  return (
    <div role="main" className="mx-6 sm:mx-auto">
      <ProgressBars />
      <div className="mt-4 mb-1 sm:mb-4">
        <div className="text-xl sm:text-4xl font-extrabold">
          Bank Credentials
        </div>
      </div>
      <div className="tracking-wide font-light sm:text-lg mb-6">
        Please confirm your bank details. This will be the account used to
        withdraw payments.
      </div>

      <Error />

      <div className="column">
        <div role="error" className={!bankProblem ? 'hidden' : undefined}>
          <Alert
            containerClass="bg-background-error p-4 mx-auto my-3"
            icon={['far', 'triangle-exclamation']}
            alertText="There was a problem validating your bank. Please double check the
          routing and account number and try again."
          />
        </div>

        <Formik
          innerRef={form}
          initialValues={{
            agree: '',
            bank_routing: '',
            bank_account: '',
            account_holder: '',
            user_id: user.id,
          }}
          validate={async (values) => {
            const errors = {};

            if (!values.agree) errors.agree = '* Required';

            if (!values.bank_routing) {
              errors.bank_routing = '* Required';
            } else if (values.bank_routing.trim().length < 9) {
              errors.bank_routing =
                'Routing No. must be exactly 9 characters long';
            }

            if (!values.account_holder) errors.account_holder = '* Required';
            if (!values.bank_account) errors.bank_account = '* Required';

            return errors;
          }}
          onSubmit={save}
        >
          {({ isSubmitting, touched, errors, values }) => (
            <Form>
              <ColumnInput
                error={errors.bank_routing}
                touched={touched.bank_routing}
              >
                <StickyInput
                  id="bank_routing"
                  inputMode="numeric"
                  mask="999999999"
                  placeholder="Routing Number"
                  values={values}
                  disclaimer={
                    <Tooltip
                      tooltipText={
                        <img
                          src="https://creditkey-assets.s3-us-west-2.amazonaws.com/ck-check-assets/check-routing.svg"
                          className="w-full"
                        />
                      }
                      triggerClass="sm:px-1 sm:mt-1 text-xs sm:text-sm hover:cursor-pointer hover:text-primary-blue text-left"
                      containerClass="rounded-lg w-72 p-3 bg-border-gray text-xs"
                      placement="left"
                    >
                      <FontAwesomeIcon
                        icon={['fal', 'circle-question']}
                        className="mr-1"
                      />
                    </Tooltip>
                  }
                />
              </ColumnInput>

              <ColumnInput
                error={errors.bank_account}
                touched={touched.bank_account}
              >
                <StickyInput
                  id="bank_account"
                  inputMode="numeric"
                  mask={/^\d*$/}
                  placeholder="Account Number"
                  values={values}
                  disclaimer={
                    <Tooltip
                      tooltipText={
                        <img
                          src="https://creditkey-assets.s3-us-west-2.amazonaws.com/ck-check-assets/check-account.svg"
                          className="w-full"
                        />
                      }
                      triggerClass="sm:px-1 sm:mt-1 text-xs sm:text-sm hover:cursor-pointer hover:text-primary-blue text-left"
                      containerClass="rounded-lg w-72 p-3 bg-border-gray text-xs"
                      placement="bottom-start"
                    >
                      <FontAwesomeIcon
                        icon={['fal', 'circle-question']}
                        className="mr-1"
                      />
                    </Tooltip>
                  }
                />
              </ColumnInput>

              <ColumnInput
                error={errors.account_holder}
                touched={touched.account_holder}
              >
                <StickyInput
                  id="account_holder"
                  placeholder="Name on Account"
                  values={values}
                />
              </ColumnInput>

              <div
                className="form-control block w-full p-3 sm:p-4 text-xs sm:text-sm text-secondary-gray bg-white bg-clip-padding border rounded-lg border-tertiary-gray rounded transition ease-in-out m-0 focus:outline-none h-40 max-h-40 overflow-y-scroll"
                dangerouslySetInnerHTML={bankAgreement}
              />

              <div className="relative flex flex-col items-start my-2 sm:my-3">
                <div className="flex items-center gap-3 m-2">
                  <div className="flex h-6 items-center">
                    <Field
                      id="agree"
                      name="agree"
                      value="yes"
                      aria-describedby="agree-checkbox"
                      type="checkbox"
                      className={classNames(
                        'h-3 sm:h-4 w-3 sm:w-4 rounded border-tertiary-gray focus:ring-primary-blue mt-1 xs:mt-0',
                        { 'border-error': errors.agree },
                      )}
                    />
                  </div>
                  <div className="text-xs sm:text-sm leading-2">
                    <label htmlFor="agree">
                      I agree to the above terms and authorize Credit Key to
                      charge scheduled charges to my bank account according to
                      those terms.
                    </label>
                  </div>
                </div>
                <ErrorMessage
                  name="agree"
                  component="div"
                  className={formStyles.validation}
                />
              </div>
              <PrimaryButton
                buttonText="Next"
                buttonColor="bg-primary-blue"
                hoverClass="hover:bg-primary-blue-hover"
                disabled={isLoading}
                isLoading={isLoading}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
