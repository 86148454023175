import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../shared/Loading';
import ProgressBars from '../../shared/ProgressBars';
import UserTokenVerification from '../../shared/UserTokenVerification';
import useSessionEntity from '../../../hooks/useSessionEntity';
import { afterAuthRoute, loading } from '../../../actions/sessions';
import { setToken, tokenIsExpired } from '../../../actions/authentication';

export default function PinVerification({ location, match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const application = useSessionEntity('application');
  const isLoading = useSelector((state) => state.sessions.get('loading'));
  const token = useSelector((state) => state.authentication.get('token'));

  const exceededPhoneChanges = () => application.phone_change_attempts >= 1;

  const tryAnotherOptionText = () => {
    if (!exceededPhoneChanges()) {
      return (
        <div className="flex flex-col xxs:flex-row items-center w-full my-5 xxs:mt-8 xxs:mb-6">
          <p className="font-light text-sm sm:text-lg">Didn't receive the code?</p>
          <Link
            to="/purchase/phone"
            className="ml-1 text-sm sm:text-lg text-primary-blue hover:text-primary-blue-hover"
          >
            Change Number
          </Link>
        </div>
      );
    }
  };

  const afterAuthCallback = (twofactor) => {
    return dispatch(afterAuthRoute(history, twofactor));
  };

  const onLockout = () => {
    history.push('/init/user_lockout');
  };

  const onNotBorrower = () => {
    history.push('/init/user_is_not_borrower');
  };

  return (
    <div role="main"  className="mx-6 sm:mx-auto">
      <ProgressBars />
      <div className="mt-4 mb-1 sm:mb-4">
        <div className="text-xl sm:text-4xl font-extrabold">
          We just texted you
        </div>
      </div>
      <div className="tracking-wide mb-6 font-light text-sm sm:text-lg">
        Please enter the verification code we sent to
        <span className="mx-1">{match.params.contact}.</span>
      </div>

      <div className="flex justify-start">
        <UserTokenVerification
          expired={tokenIsExpired(token)}
          isLoading={isLoading}
          match={match}
          onAfterAuth={afterAuthCallback}
          onLoading={loading}
          onLockout={onLockout}
          onNotBorrower={onNotBorrower}
          onSaveToken={setToken}
          twofactor={true}
        >
          <Loading className="w-14 h-14" />
        </UserTokenVerification>
      </div>

      {tryAnotherOptionText()}
    </div>
  );
}
