import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Loading from '../../shared/Loading';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import OutlineButton from '../../shared/buttons/OutlineButton';
import useSessionEntity from '../../../hooks/useSessionEntity';
import { formatCurrency } from '../../../lib/utils/formatters';
import { pi4Confirm } from '../../../actions/sessions';

export default function Conversion() {
  const dispatch = useDispatch();
  const history = useHistory();
  const cart = useSessionEntity('shopping_cart');
  const merchant = useSessionEntity('merchant');
  const user = useSessionEntity('user');

  const cancel = () => (window.location.href = merchant.url);

  const next = () => {
    dispatch(pi4Confirm());
    return history.push('/init/new_user');
  };

  if (!user && !cart) return <Loading className="w-14 h-14" containerClass="my-24 sm:my-0" />;

  return (
    <div role="main"  className="mx-6 sm:mx-auto">
      <div className="my-4 flex items-center">
        <span className="fa-layers fa-fw text-5xl">
          <FontAwesomeIcon
            icon="square"
            className="text-secondary-blue !mx-0"
          />
          <FontAwesomeIcon
            icon={["far", "thumbs-up"]}
            className="text-primary-blue text-3xl !mx-1.5"
          />
        </span>
        <div className="text-xl sm:text-4xl font-extrabold">
          Hi {user.first_name}!
        </div>
      </div>

      <div className="font-light sm:text-lg tracking-wide mb-3 sm:mb-6">
        You’re approved for Credit Key’s Pay in 4
        {cart ? (
          <span>
            , but this{' '}
            <span className="font-semibold tracking-wide">
              {formatCurrency(cart.grand_total)}
            </span>{' '}
            purchase requires a Credit Key Business approval.
          </span>
        ) : (
          '.'
        )}{' '}
        This is a separate application process.
      </div>
      <div className="font-light sm:text-lg tracking-wide mb-3 sm:mb-6">
        Get approved for Credit Key Business in less than 5 minutes, with credit
        up to $50k and terms as long as 12 months.
      </div>
      <div className="font-light sm:text-lg tracking-wide mb-3 sm:mb-6">
        If you don’t have a business, or don’t want to apply, you can continue
        to use Pay in 4 for smaller purchases.
      </div>

      <div className="flex flex-col sm:flex-row sm:justify-evenly items-center sm:gap-4 mt-4 sm:mt-0">
        <PrimaryButton
          buttonText="Continue"
          buttonColor="bg-primary-blue"
          hoverClass="hover:bg-primary-blue-hover"
          onClick={next}
        />
        <OutlineButton
          buttonText="Cancel"
          buttonColor="border-primary-blue text-primary-blue hover:text-primary-blue-hover hover:border-primary-blue-hover"
          onClick={cancel}
        />
      </div>
    </div>
  );
}
