import classNames from 'classnames';

const protocol  = 'https://';
const rootURI = 'creditkey.com';

export const VERSION = '1.0.0';

// Accept Blue
export const aBToken = process.env.REACT_APP_ACCEPT_BLUE_TOKEN;
export const aBPolyFill = process.env.REACT_APP_ACCEPT_BLUE_POLYFILL_SOURCE;
export const aBSource = process.env.REACT_APP_ACCEPT_BLUE_SOURCE;

export const decisions = {
  APPROVED: 'approved',
  DECLINED: 'declined',
  PENDING: 'pending',
};

export const platform = () => {
  return process.env.REACT_APP_ENV;
}

export const server = () => {
  return process.env.REACT_APP_API;
}

const authorizedSubDomains = ['apply', 'recharge', 'vip', 'netx', 'instore'];
export const subDomains = {
  // returns a merchant public_key, prequalification_id (if present) and the url params
  parseParams: params => [params.public_key, params.prequalification_id || '', (new URL(document.location)).searchParams],
  authorized: (flag) => authorizedSubDomains.includes(flag ? flag : subDomains.subDomain()),
  subDomain: () => authorizedSubDomains.find((sd) => subDomains.rawSubDomain.includes(sd)),
  rawSubDomain: window.location.hostname.split('.')[0],
}

export const borrowerPortalUrl = process.env.REACT_APP_BORROWER_PORTAL_URI;

export const dateFormat = 'YYYY-MM-DD HH:MM:SSZ';

export const formStyles = {
  error: (error) =>
    classNames('input focus:border-primary-blue', {
      '!border-error': error,
    }),
  input: (multi, error) =>
    classNames('w-full', multi ? 'mb-2 sm:mb-2' : 'mb-2 ', {
      'mb-0 sm:mb-2': error,
    }),
  validation: 'text-error text-xxs sm:text-xs text-left',
};

export const supportPhone =  '8443343636';
export const declinedSupportPhone = '8449003330';

export const suffixes = [
  ["", ""],
  ["Jr", "Jr"],
  ["Sr", "Sr"],
  ["II", "II"],
  ["III", "III"],
  ["IV", "IV"],
  ["V", "V"],
  ["DDM", "DDM"],
  ["DDS", "DDS"],
  ["DO", "DO"],
  ["ESQ", "ESQ"],
  ["MD", "MD"],
  ["RN", "RN"]
]

export const business_structures = [
  ["", ""],
  ["Private Company", "Private Company"],
  ["Publicly Traded Company (NYSE, NASDAQ, etc.)", "Publicly Traded Company (NYSE, NASDAQ, etc.)"],
  ["Government", "Government"],
  ["School", "School"],
  ["Non-profit", "Non-profit"],
]

export const business_types = [
  ["", ""],
  ["Corporation", "Corporation"],
  ["LLC", "LLC"],
  ["Sole Proprietorship", "Sole Proprietorship"],
  ["Partnership", "Partnership"],
  ["Nonprofit corporation", "Nonprofit corporation"],
  ["Federal", "Federal"],
  ["Municipal", "Municipal"],
]

export const states = [
  ["", ""],
  ["Alabama", "AL"],
  ["Alaska", "AK"],
  ["Arizona", "AZ"],
  ["Arkansas", "AR"],
  ["California", "CA"],
  ["Colorado", "CO"],
  ["Connecticut", "CT"],
  ["Delaware", "DE"],
  ["Florida", "FL"],
  ["Georgia", "GA"],
  ["Hawaii", "HI"],
  ["Idaho", "ID"],
  ["Illinois", "IL"],
  ["Indiana", "IN"],
  ["Iowa", "IA"],
  ["Kansas", "KS"],
  ["Kentucky", "KY"],
  ["Louisiana", "LA"],
  ["Maine", "ME"],
  ["Maryland", "MD"],
  ["Massachusetts", "MA"],
  ["Michigan", "MI"],
  ["Minnesota", "MN"],
  ["Mississippi", "MS"],
  ["Missouri", "MO"],
  ["Montana", "MT"],
  ["Nebraska", "NE"],
  ["Nevada", "NV"],
  ["New Hampshire", "NH"],
  ["New Jersey", "NJ"],
  ["New Mexico", "NM"],
  ["New York", "NY"],
  ["North Carolina", "NC"],
  ["North Dakota", "ND"],
  ["Ohio", "OH"],
  ["Oklahoma", "OK"],
  ["Oregon", "OR"],
  ["Pennsylvania", "PA"],
  ["Rhode Island", "RI"],
  ["South Carolina", "SC"],
  ["South Dakota", "SD"],
  ["Tennessee", "TN"],
  ["Texas", "TX"],
  ["Utah", "UT"],
  ["Vermont", "VT"],
  ["Virginia", "VA"],
  ["Washington", "WA"],
  ["Washington DC", "DC"],
  ["West Virginia", "WV"],
  ["Wisconsin", "WI"],
  ["Wyoming", "WY"],
]

export const streetTypes = ['avenue', 'ave', 'boulevard', 'blvd', 'street', 'st', 'road', 'rd', 'drive', 'dr', 'court', 'ct', 'lane', 'ln', 'circle', 'cir'];
export const directionalAbbreviations = ['n','north','s','south','e','east','w','west', 'nw', 'sw', 'ne', 'se'];

export const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
export const poBoxRegex = /^((((p|P)[\s\.]?[(o|O)\s][\.]?)\s?)|(post\s?office\s?))((|BOX|Box|box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/
export const nameRegex = /^[A-Za-z ]+$/

export const checkEmail = (val) => {
  const tld = val.substr(val.lastIndexOf('.') + 1);

  if (
    [
      'con',
      'cok',
      'fom',
      'comm',
      'coml',
      'comt',
      'ccom',
      'ney',
      'edi',
      'cpm',
      'comq',
      'com2',
    ].includes(tld)
  )
    return false;

  return true;
};
